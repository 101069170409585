// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   hmr       : false,
//   apiUrl: 'http://localhost:8080/itrainapi',
//   cdnUrl: 'https://cdn.itrainstation.com',
//   thumbDir: 'assets/thumbs',
//   avatarDir: 'assets/images/avatars',
//   siteUrl: 'http://localhost:4200'
// };

export const environment = {
  production: true,
  hmr       : false,
  apiUrl: 'https://itrainstation.me/itrainapi',
  cdnUrl: 'https://cdn.itrainstation.com',
  thumbDir: 'assets/thumbs',
  avatarDir: 'assets/images/avatars',
  siteUrl: 'https://itrainstation.me'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
